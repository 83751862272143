import React, { useState } from 'react';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment-timezone';
import { useRef } from 'react';


//MUI compontents
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';


//page imports
import Navbar from '../components/Navbar';

//Firebase
import {db} from '../firebase';
import { doc, setDoc, getDoc} from 'firebase/firestore';	

import Item from '../components/Item';
import CustomContainer from '../components/CustomContainer';

import attProcessing from '../components/attProcessing';
import vzwProcessing from '../components/vzwProcessing';


function CommonAnalysis() {
	
	const generateTemplate = function() {
		// Generate an excel template for the user to download
		// Create a new workbook
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Sheet1');
		worksheet.columns = [
			{ header: 'Dataset 1', width: 20 },
			{ header: 'Dataset 2', width: 20 },
			{ header: 'Dataset 3', width: 20 }
		];
		// Add data to cell A2
		worksheet.getCell('A2').value = 'Enter data you want to compare in each column';
		worksheet.getCell('A3').value = 'You can add as many columns as you want';
		worksheet.getCell('A4').value = 'The top row is for column headers, for example, LPR site 1';



		// Save the workbook
		workbook.xlsx.writeBuffer().then(function(data) {
			const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'CommonAnalysisTemplate.xlsx';
			a.click();
			window.URL.revokeObjectURL(url);
		});
	}


	const uploadFileClicked = function() {
		fileInput.current.click();
		
	}

	function handleFileChange(e) {
		setFile(e.target.files[0]);
		setFileName(e.target.files[0].name)
		setUploadButtonState(true);
	}
	
	const [file, setFile] = useState();
	const [fileName, setFileName] = useState('Select a file');
	const fileInput = useRef();
	const [uploadButtonState, setUploadButtonState] = useState(false);
		


	async function processFileClicked() {


		
		setUploadButtonState(false)
		

		
	

	
		const reader = new FileReader();
		
		reader.onload = async function (e) {
			const bstr = e.target.result;
			const workbook = new ExcelJS.Workbook();
			await workbook.xlsx.load(bstr);

	
			// get the first sheet
			var worksheet = workbook.getWorksheet('Sheet1');
		
	
			
			// Create a new worksheet
			var commonWorksheet = workbook.addWorksheet('Common Analysis');
	
			
			//Count columns in the worksheet
			const columnCount = worksheet.actualColumnCount;

			//Get all data fromn each column in worksheet and store in an array
			const columnData = [];
			for (let i = 1; i <= columnCount; i++) {
				const column = worksheet.getColumn(i);
				const columnValues = column.values;

				columnData.push(columnValues);
			}

			
			//remove all null values from columnData
			

			//columnData = [["Dataset 1",1111,1111,223,434,34,55],["Dataset 2",1,2,3,34],["Dataset 3",4,5,6,434,34,55]];


			const data = columnData.map(column => column.slice(1));

			let commonValues = [];

			// Compare each pair of columns
			for (let i = 0; i < data.length; i++) {
			for (let j = i + 1; j < data.length; j++) {
				// Find the common values between the two columns
				const common = data[i].filter(value => data[j].includes(value));

				// Add the common values to the result along with the column names
				common.forEach(value => {
				commonValues.push({
					value: value,
					columns: [columnData[i][1], columnData[j][1]]
				});
				});
			}
			}

			// Group by value and merge the column names
			const grouped = commonValues.reduce((acc, {value, columns}) => {
			acc[value] = (acc[value] || []).concat(columns);
			return acc;
			}, {});

			// Remove duplicates and convert to array
			const result = Object.entries(grouped).map(([value, columns]) => ({
			value: value,
			columns: [...new Set(columns)]
			}));

			result.forEach(item => {

				item.commonCount = item.columns.length;
				item.columns = item.columns.join(', ');
			});

			//sort result from biggest to smallest common count
			result.sort((a, b) => b.commonCount - a.commonCount);

			// Add the result to the new worksheet
			commonWorksheet.columns = [
				{ header: 'Value', key: 'value', width: 20 },
				{ header: 'Datasets', key: 'columns', width: 20 },
				{ header: 'Common Count', key: 'commonCount', width: 20 }
			];
			commonWorksheet.addRows(result);

			// set font size for the worksheet
			commonWorksheet.eachRow(function(row, rowNumber) {
				row.font = { size: 12 };
			});


			
			// Remove the old worksheet
			//workbook.removeWorksheet('Modified Sheet');
	
			// Convert the modified workbook to a buffer
			const buffer = await workbook.xlsx.writeBuffer();
	

			// Create a Blob from the buffer
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	
			// Create a temporary URL for the Blob
			const url = URL.createObjectURL(blob);
	
			// Create a link element and set its attributes
			const link = document.createElement('a');
			link.href = url;
			link.download = 'Generated-Common-Analysis.xlsx';
	
			// Simulate a click on the link to trigger the file download
			link.click();

			setFileName("Select a file")

	
	

		};
		reader.readAsArrayBuffer(file);
	
	
	}




	return(
		<>
			<CustomContainer>	
				
				<Grid xs={12} sm={12} item>
					<Navbar pageTitle={"Common Analysis"} />
				</Grid>
				<Grid xs={12} sm={12} item>
					<Item>
						<Typography variant="body1"  >
							Use this tool to find common items between datasets such as LPR data or phone calls. Upload an ".xlsx" file with each dataset you want to compare as a new column. It is recommended you download the below template and paste your data into that template. 
						
						</Typography>
						<Button variant="contained" color="primary" onClick={generateTemplate}>Download Template</Button>
					</Item>
					<br/>
					<Item>
						<Typography variant="body1"  >
							Upload your file here to run common analysis <br/> <br/> Once processed, a new file will be downloaded with the results in the "Common Analysis" sheet.
						</Typography>

						<Typography variant="body2" className="file-name-label" >
								{fileName}
						</Typography>
						<input
							type="file"
							ref={fileInput}
							onChange={handleFileChange}
							style={{ display: 'none' }} // Hide the default file input
						/>
						<Button fullWidth variant="outlined" color="primary" onClick={uploadFileClicked}>
							Choose File
						</Button>

						<br/><br/>
						<Button fullWidth variant="contained" color="primary" disabled={!uploadButtonState} onClick={processFileClicked}> Process File</Button>
						
					</Item>
				</Grid>
			</CustomContainer>

			







			
		</>
		

	)

}

export default CommonAnalysis;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';


//Import firebase auth
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

import Item from '../components/Item';
import CustomContainer from '../components/CustomContainer';


function Login() {
	const navigate = useNavigate();
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');

	function doLogin() {
		signInWithEmailAndPassword(auth, email, password)
		.then(function(userCredential) {
			console.log(userCredential.user)
			// Navigate to the home page
			navigate('/');
		})
		.catch(function(error){
			alert('bad login')
		})
	}


	return (
		<>
			<CustomContainer>	
				
				<Grid xs={12} sm={12} item>
					<Navbar pageTitle={"Login"} />
				</Grid>

				<Grid xs={12} sm={12} item>
					<Item>
						<TextField id="email" label="Email" variant="outlined" fullWidth onChange={(e) => setEmail(e.target.value)} /><br/><br/>
						<TextField id="password" label="Password" variant="outlined" type="password" fullWidth onChange={(e) => setPassword(e.target.value)} /><br/><br/>
						<Button variant="contained" fullWidth onClick={doLogin}>Log In</Button>


					</Item>
				</Grid>
			</CustomContainer>

			
			
		</>
	);
}

export default Login;
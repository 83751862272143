import React, { useState } from 'react';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment-timezone';
import { useRef } from 'react';


//MUI compontents
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';


//page imports
import Navbar from '../components/Navbar';

//Firebase
import {db} from '../firebase';
import { doc, setDoc, getDoc} from 'firebase/firestore';	

import Item from '../components/Item';
import CustomContainer from '../components/CustomContainer';

import attProcessing from '../components/attProcessing';
import vzwProcessing from '../components/vzwProcessing';


function ExcelMan() {
	const [file, setFile] = useState();
	const [fileName, setFileName] = useState('Select a file');
	const fileInput = useRef();

	const[timeZone, setTimeZone] = useState('America/New_York');
	const timeZones = moment.tz.names();

	const [processingButtonText, setProcessingButtonText] = useState('Process File');
	const [TMobileButtonState, setTMobileButtonState] = useState(false);

	const [phoneCompany, setPhoneCompany] = useState('tmobile');

	const [topCallersListLength, setTopCallersListLength] = useState(10);
	
	//State Functions
	const doUpdateTimezone = function(e){
		setTimeZone(e.target.value);
	}

	function handleFileChange(e) {
		setFile(e.target.files[0]);
		setFileName(e.target.files[0].name)
		

	}

	function processError(message){
		alert(message);
		setProcessingButtonText('Process File');
		setTMobileButtonState(false);
	}
	


	async function handleFileRead() {
		setProcessingButtonText('Processing...');
		setTMobileButtonState(false);

		if(phoneCompany === 'att'){

			attProcessing(file, timeZone, topCallersListLength, function(url){
				
				// Create a link element and set its attributes
				const link = document.createElement('a');
				link.href = url;
				link.download = 'ATT_modified_records.xlsx';

				// Simulate a click on the link to trigger the file download
				link.click();
				setProcessingButtonText('Process File');
				updateStats();
				return false;

			});
			return false;
			
		} else if(phoneCompany === "vzw") {

			vzwProcessing(file, timeZone, topCallersListLength, function(url){
				const link = document.createElement('a');
				link.href = url;
				link.download = 'VZW_modified_records.xlsx';

				// Simulate a click on the link to trigger the file download
				link.click();
				setProcessingButtonText('Process File');
				updateStats();
				return false;
			});
			return false;
		}
	

		console.log('phone company', phoneCompany);
	
		const reader = new FileReader();
		
		reader.onload = async function (e) {
			const bstr = e.target.result;
			const workbook = new ExcelJS.Workbook();
			await workbook.xlsx.load(bstr);

	
			// get the first sheet
			var worksheet = workbook.getWorksheet('1. Call Data');
			var CLEARWorksheet = workbook.getWorksheet('CLEAR');
	
			if(!worksheet)
				return processError('Error: Could not find the "1. Call Data" sheet. Make sure you upload the correct file.');

			// Create a new worksheet
			var newWorksheet = workbook.addWorksheet('Timezone and CLEAR Mods');
	
			// Add a new column between columns F and G
			var newColumns = [...worksheet.columns];
			newColumns.splice(7, 0, { header: 'New Column', key: 'newColumn' });
			newWorksheet.columns = newColumns;
	
			// Copy the data from the old worksheet to the new one
			// Copy the data from the old worksheet to the new one
			worksheet.eachRow((row, rowNumber) => {
				var newRowValues = [...row.values.slice(1)]; // Slice from the 1st index
				newRowValues.splice(7, 0, null); // Add a null value for the new column
				newWorksheet.addRow(newRowValues);
			});

			// Adjust the width of the columns
			newWorksheet.columns.forEach(column => {
				column.width = 15;
			});

			// Set the value of Colum G row 9 to "Calling Name"
			newWorksheet.getCell('H8').value = 'Caller or Called Name (Clear BatchLookup)';


			
			// Get clear worksheet 
			if(CLEARWorksheet){
				//Loop through each cell in column F of updatedWorkSheet starting at row 10
				var cellValue = '';
				for(var i = 9; i < newWorksheet.rowCount; i++){

					
					if(newWorksheet.getCell('E'+i).value === 'Outgoing'){
						cellValue = newWorksheet.getCell('G' + i).value;
					}
					else{
						cellValue = newWorksheet.getCell('F' + i).value;
					}

					//Get the value of the cell in column F of updatedWorkSheet at row i
					for(var x = 0; x < CLEARWorksheet.rowCount; x++){
						//Get the value of the cell in column F of CLEARWorksheet at row x
						var CLEARCellValue = CLEARWorksheet.getCell('L' + x).value;
						//If the cell value in column F of CLEARWorksheet at row x is equal to the cell value in column F of updatedWorkSheet at row i
						if(CLEARCellValue === cellValue){
							var name = CLEARWorksheet.getCell('N' + x).value;
							newWorksheet.getCell('H' + i).value = name;
						
						}
					}

				}
			}
			
			//delete rows 1-7 in newWorksheet
			newWorksheet.spliceRows(1, 7);

			

		
			dayjs.extend(utc);
			dayjs.extend(timezone);
			//loop through each row in newWorksheet
			for(var i = 1; i < newWorksheet.rowCount; i++){
				//get value of cell in column B of newWorksheet at row i
				var cellValue = newWorksheet.getCell('B' + i).value;
				var cellDate = new Date(cellValue);
				var formattedDate = dayjs(cellDate).tz(timeZone).format('MM/DD/YYYY HH:mm:ss');
				newWorksheet.getCell('B' + i).value = formattedDate;
			}
			newWorksheet.getCell('B1').value = "Date Time in " + timeZone + " Timezone	";
			newWorksheet.getCell('A1').value = "UTC Date / Time";


			//Loop through newWorksheet and get top 10 most common items in column F
			var incomingNumbersArray = [];
			var outgoingNumbersArray = [];
			var callDirection = '';

			for(var i = 1; i < newWorksheet.rowCount; i++){
				callDirection = newWorksheet.getCell('E' + i).value;

				
				var callerName = newWorksheet.getCell('H' + i).value;
				if(callDirection === 'Incoming'){
					var cellValue = newWorksheet.getCell('F' + i).value;
					incomingNumbersArray.push({phoneNumber: cellValue+"", callerName: callerName});
				}else if(callDirection === 'Outgoing'){
					var cellValue = newWorksheet.getCell('G' + i).value;
					outgoingNumbersArray.push({phoneNumber: cellValue+"", callerName: callerName});
				}
			}
	
			// Count the frequency of each value in the array
			const frequencyIncoming = incomingNumbersArray.reduce((acc, val) => {
				acc[val.phoneNumber] = (acc[val.phoneNumber] || 0) + 1;
				return acc;
			}, {});

			const frequencyOutgoing = outgoingNumbersArray.reduce((acc, val) => {
				acc[val.phoneNumber] = (acc[val.phoneNumber] || 0) + 1;
				return acc;
			}, {});

			// Convert the frequency object to an array of [value, frequency] pairs
			const frequencyIncomingArr = Object.entries(frequencyIncoming);
			const frequencyOutgoingArr = Object.entries(frequencyOutgoing);

			// Sort the array by frequency in descending order
		
			frequencyIncomingArr.sort((a, b) => b[1] - a[1]);
			frequencyOutgoingArr.sort((a, b) => b[1] - a[1]);

			// Get the top 10 most common values
			const top10Incoming = frequencyIncomingArr.slice(0, topCallersListLength).map(pair => pair[0]);
			const top10Outgoing = frequencyOutgoingArr.slice(0, topCallersListLength).map(pair => pair[0]);
			

			
			//Add a new sheet for top Callers
			var topCallersWorksheet = workbook.addWorksheet('Top Callers');
			
			topCallersWorksheet.mergeCells('A1:B1');
			topCallersWorksheet.getCell('A1').value = "Incoming Top Callers";
			//center the value of A1
			topCallersWorksheet.getCell('A1').alignment = { horizontal: 'center' };
			topCallersWorksheet.getCell('A1').font = { bold: true };

			topCallersWorksheet.getCell('A2').value = "Number of Incoming Calls";
			topCallersWorksheet.getCell('B2').value = "Incoming Number";

			
			//topCallersWorksheet.getCell('B2').value = "Callers name (Clear Lookup)";
			for(var i = 0; i < top10Incoming.length; i++){
				topCallersWorksheet.getCell('A' + (i+3)).value = frequencyIncoming[top10Incoming[i]];
				topCallersWorksheet.getCell('B' + (i+3)).value = top10Incoming[i];

				/*
				if(CLEARWorksheet){
					var obj = incomingNumbersArray.find(o => o.phoneNumber == top10Incoming[i]);
					topCallersWorksheet.getCell('C' + (i+3)).value = obj.callerName;
				}
				*/
			}


			
			
			var outgoingListOffset = parseInt(topCallersListLength)+5;

			topCallersWorksheet.mergeCells('A'+outgoingListOffset+':B'+outgoingListOffset);
			topCallersWorksheet.getCell('A'+outgoingListOffset).value = "Outgoing Top Callers";
			//center the value of A15
			topCallersWorksheet.getCell('A'+outgoingListOffset).alignment = { horizontal: 'center' };
			topCallersWorksheet.getCell('A'+outgoingListOffset).font = { bold: true };

			var temp = outgoingListOffset+1;
			topCallersWorksheet.getCell('A'+temp).value = "Number of Outgoing Calls";
			topCallersWorksheet.getCell('B'+temp).value = "Outgoing Number";
			
			for(var i = 0; i < top10Outgoing.length; i++){
				topCallersWorksheet.getCell('A' + (i+outgoingListOffset+2)).value = frequencyOutgoing[top10Outgoing[i]];
				var number = top10Outgoing[i];

				topCallersWorksheet.getCell('B' + (i+outgoingListOffset+2)).value = number;
				
				
				//if(CLEARWorksheet){
				//	var obj = incomingNumbersArray.find(o => o.phoneNumber == top10Incoming[i]);
				//	topCallersWorksheet.getCell('C' + (i+3)).value = obj.callerName;
				//}
				
			}


		

			//Loop through and get top 10 Towers
			//Combine W X Y columns into string
			var towerArray = [];
			for(var i = 1; i < newWorksheet.rowCount; i++){
				var towerAddress = newWorksheet.getCell('W' + i).value;
				if(towerAddress) {
					var towerCity = newWorksheet.getCell('X' + i).value;
					var towerState = newWorksheet.getCell('Y' + i).value;
					
					var addressStirng = towerAddress + " " + towerCity + " " + towerState;
					
					towerArray.push(addressStirng);
				}
				
			}
			// Get the top 10 most common values in towerArray and sort them in descending order
			const top10Towers = towerArray.reduce((acc, val) => {
				acc[val] = (acc[val] || 0) + 1;
				return acc;
			}, {});


			
			const towerFrequency = Object.entries(top10Towers);
			towerFrequency.sort((a, b) => b[1] - a[1]);
			const top10TowerList = towerFrequency.slice(0, topCallersListLength).map(pair => pair[0]);

			var towerListOffset = outgoingListOffset+parseInt(topCallersListLength)+5;
			if(top10TowerList.length > 0){
				topCallersWorksheet.mergeCells('A'+towerListOffset+':B'+towerListOffset);
				topCallersWorksheet.getCell('A'+towerListOffset).value = "Top Cell Sites";
				//center the value of A15
				topCallersWorksheet.getCell('A'+towerListOffset).alignment = { horizontal: 'center' };
				topCallersWorksheet.getCell('A'+towerListOffset).font = { bold: true };

				var temp = towerListOffset+1;
				topCallersWorksheet.getCell('A'+temp).value = "Tower Hits";
				topCallersWorksheet.getCell('B'+temp).value = "Tower Address";
				

				for(var i = 0; i < top10TowerList.length; i++){
					topCallersWorksheet.getCell('A' + (i+towerListOffset+2)).value = top10Towers[top10TowerList[i]];
					topCallersWorksheet.getCell('B' + (i+towerListOffset+2)).value = top10TowerList[i];
				}
			}



			
		
			//Clean up the spreadsheet and set column widths
			newWorksheet.getColumn('A').width = 15.7;
			newWorksheet.getColumn('B').width = 36.5;
			newWorksheet.getColumn('H').width = 35.7;

			topCallersWorksheet.getColumn('A').width = 21.8;
			topCallersWorksheet.getColumn('B').width = 16.6;

			// Change the format of cell B3 in topCallersWorksheet to a phone number
			

			//Add a new row at the top of the worksheet
			//newWorksheet.spliceRows(1, 0, ["MODIFIED T-MOBILE CDRs. CONFIRM DATA IS CORRECT ON ORIGINAL CDR RECORDS"]);


			//Set font size of all cells in newWorksheet to 12
			newWorksheet.eachRow(function(row, rowNumber) {
				row.eachCell(function(cell, colNumber) {
					cell.font = {size: 12};
				});
			});

			//Set font size of all cells in newWorksheet to 12
			topCallersWorksheet.eachRow(function(row, rowNumber) {
				row.eachCell(function(cell, colNumber) {
					cell.font = {size: 12};
				});
			});

			

			// Remove the old worksheet
			//workbook.removeWorksheet('Modified Sheet');
	
			// Convert the modified workbook to a buffer
			const buffer = await workbook.xlsx.writeBuffer();
	

			// Create a Blob from the buffer
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	
			// Create a temporary URL for the Blob
			const url = URL.createObjectURL(blob);
	
			// Create a link element and set its attributes
			const link = document.createElement('a');
			link.href = url;
			link.download = 'modified_file.xlsx';
	
			// Simulate a click on the link to trigger the file download
			link.click();

			setFileName("Select a file")

			//Add stats to firebase
			updateStats();

			setProcessingButtonText('Process File');

		};
		reader.readAsArrayBuffer(file);
	
	}

	//Firebase tracking
	var updateStats = async function(){
		const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
		if (isLocalhost) {
			return;
		}

		const docRef = doc(db, "stats", "CDR");
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			var data = docSnap.data();
			var tMobileCurrent = data.tmobile;
			var attCurrent = data.att;
			var vzwCurrent = data.vzw;

			if(phoneCompany === 'tmobile'){
				tMobileCurrent = data.tmobile + 1
			}else if(phoneCompany === 'att'){
				attCurrent = data.att + 1
			}else if(phoneCompany === 'vzw'){
				vzwCurrent = data.vzw + 1
			}

			await setDoc(doc(db, "stats", "CDR"), {
				"att": attCurrent,
				"vzw": vzwCurrent,
				"tmobile": tMobileCurrent,
			});
			
		} 
	}
	

	


	//State handlers for UI

	const handleButtonClick = () => {
		fileInput.current.click();
		setTMobileButtonState(true);
	};

	const [alignment, setAlignment] = React.useState('left');

	const handleChange = (event, newAlignment) => {
		setAlignment(newAlignment);
		if(event.target.value === 'left')
			setPhoneCompany('tmobile');
		else if(event.target.value === 'center')
			setPhoneCompany('att');
		else if(event.target.value === 'right')
			setPhoneCompany('vzw');

	};
	const control = {
		value: alignment,
		onChange: handleChange,
		exclusive: true
	};
		



	const callersListLengthValidation = function(e){
		console.log(e.target.value);
		//check if e.target.value is a number
		if(isNaN(e.target.value)){
			setTopCallersListLength(10);
		}

	};
		

	return(
		<>
			<CustomContainer>	
				
				<Grid xs={12} sm={12} item>
					<Navbar pageTitle={"CDR Processing"} />
				</Grid>
				<Grid xs={12} sm={12} item>
					<Item>
						<Typography variant="h6"  >
							Choose the phone company 
						</Typography>
						
						

						<ToggleButtonGroup size="large" {...control} aria-label="Large sizes" fullWidth>
							<ToggleButton value="left" key="left">
								T-Mobile
							</ToggleButton>
							<ToggleButton value="center" key="center">
								AT&T
							</ToggleButton>
							<ToggleButton value="right" key="right">
								Verizon 
							</ToggleButton>
						</ToggleButtonGroup>


						{phoneCompany === 'tmobile' && 
							<div style={{ marginTop: '20px' }}>
								<Typography variant="body1"  >
									This CDR tool generates a new CDR file derived from your original. The resulting file includes a new sheet with the CDR's in both UTC and LOCAL time along with a sorted list of the top incoming and outgoing callers. If tower data was supplied, the new sheet includes a the top tower list. 
									<br/> <br/>Names associated with the phone numbers are optionally retrieved through a CLEAR batch search. If you would like to include that data, follow the steps below.
									<br/><br/>Instructions: Upload the T-Mobile CDR file you want to process. Typically, the file starts with "CDR_Mediations_". You must load a .xlsx file. For additional instructions on adding the CLEAR batch search results, see below.
								</Typography>
				
								<div variant="body2" >
									<ol>
										<li>Select all the phone numbers from the "Calling" column and the "Called" column. </li>
										<li>Copy and paste those numbers into a seperate blank excel file in one column.</li>
										<li>Remove duplicates.</li>
										<li>Remove items that aren't phone numbers.</li>
										<li>Copy and paste that list into the CLEAR batch phone search tool.</li>
										<li>Once that file is ready, download the file.</li>
										<li>Copy and paste the contents of that file into a new sheet in the CDR file called "CLEAR".</li>
										<li>Upload that modified file.</li>
									</ol>
								</div>							
							</div>
						}
						{phoneCompany === 'att' && 
							<div style={{ marginTop: '20px' }}>
								<Typography variant="body1"  >
									This CDR tool generates a new CDR file derived from the original AT&T text file. The resulting excel file includes a sheet with the CDR's in both UTC and LOCAL time along with a sorted list of the top incoming and outgoing callers.  <br/><br/>Note: Data usage is not included in the generated spreadsheet.
								</Typography>
								<br/>
								<Typography variant="body1"  >
									Instructions: Upload the AT&T text file you want to process. Typically, the file name is "ReportAU_" followed by a set of numbers. Make sure you upload the text file, not the PDF.
								</Typography>			
							</div>
						}
						{phoneCompany === 'vzw' && 
							<div style={{ marginTop: '20px' }}>
								<Typography variant="body1"  >
									This CDR tool generates a new CDR file derived from the original Verizon Wireless CSV file. The resulting excel file includes a sheet with the CDR's in both UTC and LOCAL time along with a sorted list of the top incoming and outgoing callers.  
								</Typography>
								<br/>
								<Typography variant="body1"  >
									Instructions: Upload the Verizon CDR file you want to process. Typically, the filename starts with: "CDR-4G_VOLTE__". The file must be a .csv file.
								</Typography>			
							</div>
						}

						

						<br/>
						<Typography variant="body1" >
							Your file will automatically download after it is processed. 
						</Typography>
					</Item>

					<Item style={{ marginTop: '20px' }}>
						
						<div className='clearfix'></div>

						<br/>
						<TextField
							id="top-callers-count"
							label="Top Callers list length"
							defaultValue="10"
							onChange={(event) => setTopCallersListLength(event.target.value)}
							onBlur={callersListLengthValidation}
							required
							/>
						<br/><br/>
						<Select 
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={timeZone}
							label="some label"
							fullWidth
							onChange={doUpdateTimezone}>
							{timeZones
							.sort((a, b) => {
								if (a.startsWith('US/')) return -1;
								if (b.startsWith('US/')) return 1;
								if (a === 'America/New_York') return -1;
								if (b === 'America/New_York') return 1;
								if (a.includes("America/") && !b.includes("America/")) {
									return -1;
								} else if (!a.includes("America/") && b.includes("America/")) {
									return 1;
								} else {
									return a.localeCompare(b);
								}
							})
							.map((timeZone, index) => (
								<MenuItem key={index} value={timeZone}>
									{timeZone}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>Timezone you want to convert to</FormHelperText>
						<br/>
						<Typography variant="body2" className="file-name-label" >
								{fileName}
						</Typography>
						<input
							type="file"
							ref={fileInput}
							onChange={handleFileChange}
							style={{ display: 'none' }} // Hide the default file input
						/>
						<Button fullWidth variant="outlined" color="primary" onClick={handleButtonClick}>
							Choose File
						</Button>
						<br/><br/>
						<Button fullWidth variant="contained" color="primary" onClick={handleFileRead} disabled={!TMobileButtonState}>
							{processingButtonText}
						</Button>
						<Typography variant="caption" display="block" >
							Files are processed locally. Nothing is uploaded to our server.
							<br/>
							Disclaimer: The generated file should be used as a work product. Refer to original CDR data for any legal process.
						</Typography>
					</Item>
				</Grid>
			</CustomContainer>

			







			
		</>
		

	)
	/*
	return (
		<div>
			<h1>Excel Manipulator</h1>
			<select onChange={doUpdateTimezone}>
				{timeZones
					.sort((a, b) => {
						if (a === 'America/New_York') return -1;
						if (b === 'America/New_York') return 1;
						if (a.includes("America/") && !b.includes("America/")) {
							return -1;
						} else if (!a.includes("America/") && b.includes("America/")) {
							return 1;
						} else {
							return a.localeCompare(b);
						}
					})
					.map((timeZone, index) => (
						<option key={index} value={timeZone}>
							{timeZone}
						</option>
					))}
			</select>
			<input type="file" onChange={handleFileChange} />
			<button onClick={handleFileRead}>Read File</button>
		</div>
	);
	*/
}

export default ExcelMan;
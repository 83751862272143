import React, { useEffect } from 'react';


//page imports
import Navbar from '../components/Navbar';

//Firebase
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';

import { useNavigate } from 'react-router-dom';

import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { auth } from '../firebase';
import Item from '../components/Item';

import CustomContainer from '../components/CustomContainer';

/*

*/




function Twilio() {
	const navigate = useNavigate();

	
	
	
	useEffect(() => {
		
		var uid = '';
		onAuthStateChanged(auth, (user) => {
			if (user) {
				uid = user.uid;
			} else {
				navigate('/');
			}
		});

	});
	

	//States
	const [lookupType, setLookupType] = React.useState('ip');
	const [inputValue, setInputValue] = React.useState('');
	const [inputValid, setInputValid] = React.useState(true);
	const [buttonActive, setButtonState] = React.useState("disabled");
	const [results, setResults] = React.useState('Results...');
	//State Functions

	//Control values for carrier lookup toggle
	const control = {
		value: lookupType,
		onChange: handleChange,
		exclusive: true
	};

	//Click event for carrier lookup toggle
	function handleChange(event) {
		setLookupType(event.target.value);
	}


	function processInput(inputValue){
		setInputValue(inputValue);
		setInputValid(true);
		setButtonState("active");
	}

	function lookupClicked(){
		setResults("Loading...");
		setButtonState("disabled");
		document.getElementById("inputField").value = "";

		
		
		lookupData(function(results){
			var resultsString = "";
			if(results.message.toLowerCase().includes("success") || results.message.toLowerCase().includes("phone is valid")){
				if(lookupType == "ip"){
					resultsString = "IP Address: "+inputValue+"\nISP: "+results.ISP+"\nActive TOR: "+results.active_tor+"\nActive VPN: "+results.active_vpn+"\nCity: "+results.city+"\nRegion: "+results.region;
				} else if (lookupType == "email"){
					resultsString = "Email: "+results.sanitized_email+"\nFirst Seen: "+results.first_seen.human+"\nDisposable: "+results.disposable
				} else if (lookupType == "phone"){
					resultsString = "Phone Number: "+results.local_format+"\nCarrier: "+results.carrier+"\nLine Type: "+results.line_type+"\nIs VOIP: "+results.VOIP+"\nIs Active: "+results.active+"\nSpammer: "+results.spammer+"\nIs Risky: "+results.risky+"\nPossible Names: "+results.name;
				}
			} else {
				resultsString = results.message;
			}
			
			setResults(resultsString);
		});
		
	}
	


	//const [phoneNumber, setPhoneNumber] = useState('');
	//var phoneNumber = '+19142618013';
	//var type = 'caller-name'
	var type = '';
	const lookupData = async function (cb) {




		
		const auth = getAuth();
		if(lookupType == "ip"){
			type = 'ip';
		} else if (lookupType == "email"){
			type = 'email';
		} else {
			type = "phone";
		}


	
		//phoneNumber

		onAuthStateChanged(auth, async (user) => { // Add async here
			if (user) {

				// Get the user's ID token
				const idToken = await user.getIdToken(true);
				//const idToken = ""


				
				//check if on localhost or production
				if(window.location.hostname === "localhost")
					var baseUrl = "http://127.0.0.1:5001/letools-pg/us-central1/"
				else
					baseUrl = "https://us-central1-letools-pg.cloudfunctions.net/"

				const response = await fetch(baseUrl+"iplookup", {
					method: 'POST',
					headers: {
						'Authorization': 'Bearer ' + idToken,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						type: type,
						lookupItem: inputValue
					})
				});

				const data = await response.json();

				cb(data)
			} else {
				console.log("user is logged out");
			}

		});
		


		
	};
	

	return(
		<>
			<CustomContainer>	
				
				<Grid xs={12} sm={12} item>
					<Navbar pageTitle={"IP Checker"} />
				</Grid>
				<Grid xs={12} sm={12} item>
					<Item>
						<Typography variant="body1"  gutterBottom>
							Lookup IP / Email / Phone Numbers
						</Typography>

						<ToggleButtonGroup size="large" {...control} aria-label="Large sizes" fullWidth>
							<ToggleButton value="ip" key="left">
								IP Search
							</ToggleButton>
							<ToggleButton value="email" key="center">
								Email Validation
							</ToggleButton>
							<ToggleButton value="phone" key="right">
								Phone Validation
							</ToggleButton>
						</ToggleButtonGroup>
						<br/><br/>
						<TextField
							id="inputField"
							label="IP Address / Email / Phone Number (no dashes)"
							variant="outlined"
							fullWidth
							onChange={(event) => processInput(event.target.value)}
							error={!inputValid}
						/>
						<br/><br/>
						<TextField
							id="results"
							multiline
							disabled
							fullWidth
							maxRows={10}
							value={results}
							/>
						<br/><br/>
						<Button variant="contained" fullWidth disabled={buttonActive === "disabled"} onClick={lookupClicked}>Lookup</Button>
						<br/><br/>
						<Typography variant="caption" display="block" >
							Disclaimer: This uses a 3rd party service.
						</Typography>
					</Item>
				</Grid>
			</CustomContainer>

		</>
		

	)

}

export default Twilio;
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { format } from 'date-fns';


function vzwProcessing(file, timeZone, topCallersListLength, cb) {

	dayjs.extend(utc);
	dayjs.extend(timezone);

	const reader = new FileReader();



	reader.onload = async function(e) {
		const bstr = e.target.result;

		const decoder = new TextDecoder('utf-8');
		const text = decoder.decode(bstr);
	

		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Timezone Mod');

		// Split the text into rows based on newline character
		const rows = text.split('\n');
		
		//Add title row to cell A1 of worksheet
		worksheet.getCell('A1').value = "Verizon CDR Data with Timezone Converstion";
		worksheet.getCell('A1').font = { bold: true };

		//Add column headers to row 2 of worksheet that is in rows[0]
		const columnHeaders = rows[0].split(',');
		//add item between index 1 and 2 of columnHeaders array
		columnHeaders.splice(2, 0, "Date Time in " + timeZone + " Timezone");

		columnHeaders.forEach(function(header, index) {
			//remove "" from header
			header = header.replace(/"/g, '');
			if(header === "Dir")
				header = "Direction";
			else if(header === "CPN") 
				header = "Number who initiated call";
			else if(header === "SOU")
				header = "Call Time (HH:MM:SS)";

			worksheet.getCell(`${String.fromCharCode(65 + index)}2`).value = header;
			worksheet.getCell(`${String.fromCharCode(65 + index)}2`).font = { bold: true };
		})

		// Loop through each row
		rows.forEach(function(row, index){
			if(index === 0)
				return true;

			const cells = row.split(',');

			//loop through each cells in and remove "" from each cell
			cells.forEach(function(cell, index){
				cell = cell.replace(/"/g, '');
				cells[index] = cell;
			})

			//Update the spreadsheet title row
			if(index === 1){
				console.log("ROW",cells)
				var cleanedPhoneNumber = cells[3];
				//format cleanedPhone number like (xxx) xxx-xxxx
				cleanedPhoneNumber = cleanedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
				worksheet.getCell('A1').value = "Verizon CDR Data with Timezone Converstion for " + cleanedPhoneNumber;
				return true;
			}

			//Add date conversion here
			var cellValue = cells[1];
			var utcDate = dayjs(cellValue).utc(true);
			var formattedDate = dayjs(utcDate).tz(timeZone).format('MM/DD/YYYY HH:mm:ss');

			// Add new item between index 1 and 2 of row array
			cells.splice(2, 0, formattedDate);

			//Reformat Direction Code
			//console.log(cells[3])
			if(cells[3] == "MT")
				cells[3] = "Incoming";
			else if(cells[3] == "MO")
				cells[3] = "Outgoing";

			//Convert seconds to HH:mm:ss
			var duration = cells[7];
			var hours = Math.floor(duration / 3600);
			var minutes = Math.floor((duration - (hours * 3600)) / 60);
			var seconds = duration - (hours * 3600) - (minutes * 60);
			cells[7] = hours + ":" + minutes + ":" + seconds;


			//Add the row to the next available row in the worksheet
			worksheet.addRow(cells);
		})





		//Add top callers list
		//Create a worksheet


		var incomingNumbersArray = [];
		var outgoingNumbersArray = [];
		var callDirection = '';

		//Loop through each row in worksheet
		var maxRowCount = worksheet.rowCount;
		
		for(var i = 2; i < maxRowCount; i++){
			callDirection = worksheet.getCell("D"+i).value;
			if(callDirection === 'Incoming'){
				var cellValue = worksheet.getCell('G' + i).value;
				incomingNumbersArray.push({phoneNumber: cellValue+""});
			}else if(callDirection === 'Outgoing'){
				var cellValue = worksheet.getCell('F' + i).value;
				outgoingNumbersArray.push({phoneNumber: cellValue+""});
			}
		}


		// Count the frequency of each value in the array
		const frequencyIncoming = incomingNumbersArray.reduce((acc, val) => {
			acc[val.phoneNumber] = (acc[val.phoneNumber] || 0) + 1;
			return acc;
		}, {});

		const frequencyOutgoing = outgoingNumbersArray.reduce((acc, val) => {
			acc[val.phoneNumber] = (acc[val.phoneNumber] || 0) + 1;
			return acc;
		}, {});


		

		// Convert the frequency object to an array of [value, frequency] pairs
		const frequencyIncomingArr = Object.entries(frequencyIncoming);
		const frequencyOutgoingArr = Object.entries(frequencyOutgoing);

		// Sort the array by frequency in descending order
		frequencyIncomingArr.sort((a, b) => b[1] - a[1]);
		frequencyOutgoingArr.sort((a, b) => b[1] - a[1]);

		// Get the top 10 most common values
		const top10Incoming = frequencyIncomingArr.slice(0, topCallersListLength).map(pair => pair[0]);
		const top10Outgoing = frequencyOutgoingArr.slice(0, topCallersListLength).map(pair => pair[0]);
		

		// Create top Incoming Caller list
		//Get all the incoming callers
		const topCallersWorksheet = workbook.addWorksheet('Top Callers');

		
		topCallersWorksheet.mergeCells('A1:B1');
		topCallersWorksheet.getCell('A1').value = "Incoming Top Callers";
		//center the value of A1
		topCallersWorksheet.getCell('A1').alignment = { horizontal: 'center' };
		topCallersWorksheet.getCell('A1').font = { bold: true };

		topCallersWorksheet.getCell('A2').value = "Number of Incoming Calls";
		topCallersWorksheet.getCell('B2').value = "Incoming Number";

		
		//topCallersWorksheet.getCell('B2').value = "Callers name (Clear Lookup)";
		for(var i = 0; i < top10Incoming.length; i++){
			topCallersWorksheet.getCell('A' + (i+3)).value = frequencyIncoming[top10Incoming[i]];
			topCallersWorksheet.getCell('B' + (i+3)).value = top10Incoming[i];
		}


		var outgoingListOffset = parseInt(topCallersListLength)+5;

		topCallersWorksheet.mergeCells('A'+outgoingListOffset+':B'+outgoingListOffset);
		topCallersWorksheet.getCell('A'+outgoingListOffset).value = "Outgoing Top Callers";
		//center the value of A15
		topCallersWorksheet.getCell('A'+outgoingListOffset).alignment = { horizontal: 'center' };
		topCallersWorksheet.getCell('A'+outgoingListOffset).font = { bold: true };

		var temp = outgoingListOffset+1;
		topCallersWorksheet.getCell('A'+temp).value = "Number of Outgoing Calls";
		topCallersWorksheet.getCell('B'+temp).value = "Outgoing Number";
		
		for(var i = 0; i < top10Outgoing.length; i++){
			topCallersWorksheet.getCell('A' + (i+outgoingListOffset+2)).value = frequencyOutgoing[top10Outgoing[i]];
			var number = top10Outgoing[i];

			topCallersWorksheet.getCell('B' + (i+outgoingListOffset+2)).value = number;
			
		}


		//Cleanup the worksheet
		worksheet.getColumn(1).width = 25;
		worksheet.getColumn(2).width = 21.67
		worksheet.getColumn(3).width = 34;
		worksheet.getColumn(4).width = 7.7;
		worksheet.getColumn(5).width = 10.35;
		worksheet.getColumn(6).width = 14.17;
		worksheet.getColumn(7).width = 20.67;
		worksheet.getColumn(8).width = 17.17;



		
		// Rest of the code...
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		const url = URL.createObjectURL(blob);
		cb(url);
	};

	reader.readAsArrayBuffer(file);


}

export default vzwProcessing;



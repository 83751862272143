import React from 'react';
import Typography from '@mui/material/Typography';
import Item from '../components/Item';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

function Navbar({ pageTitle }) {
	return (
		<>
			<Item>
				
				<Typography variant="h4">
					<Link to="/" color="primary"><HomeIcon /></Link>
					{pageTitle}
				</Typography>
			</Item>
		</>
	);
}

export default Navbar;
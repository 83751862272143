
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';






function TestPage() {
	dayjs.extend(utc);
	dayjs.extend(timezone);

	var timeZone = "America/New_York";

	var dateString = "7/1/2023 08:04:09 AM";

	var utcDate = dayjs(dateString).utc(true);
	var estNow = utcDate.clone();


	console.log(utcDate.format("M/D/YYYY hh:mm:ss A"))
	console.log(estNow.tz(timeZone).format("M/D/YYYY hh:mm:ss A"))

	/*
	now.format("M/D/YYYY hh:mm:ss A");

	var estNow = dayjs(now).tz(timeZone).format("M/D/YYYY hh:mm:ss A");
	
	console.log(now)
	console.log(estNow)
	*/

	/*
	var myDate = dayjs(dateString).utc(true).format("M/D/YYYY hh:mm:ss");
	var convertedDate = dayjs(myDate).tz(timeZone).format("M/D/YYYY hh:mm:ss");
	console.log(myDate)
	console.log(convertedDate)
	*/

	console.log("=====================================")

	
	return(
		<div>
			<h1></h1>
			<h1></h1>
		</div>
	);
}

export default TestPage;

import ExcelJS from 'exceljs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { format } from 'date-fns';


function attProcessing(file, timeZone, topCallersListLength, cb) {

	dayjs.extend(utc);
	dayjs.extend(timezone);

	const reader = new FileReader();

	reader.onload = async function (e) {
		const bstr = e.target.result;

		const decoder = new TextDecoder('utf-8');
		const text = decoder.decode(bstr);
		//console.log(text);

		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Timezone Mod');

		// Split the text into rows based on newline character
		const rows = text.split('\n');

		var cleanedPhoneNumber = "";
		
		var reachedLimit = false;
		var maxRowCount = 0;
		// Loop through each row
		rows.forEach((row, rowIndex) => {
			const cells = row.split(',');

		
			if(cells[0].includes("Data")) {
				reachedLimit = true;
				return false;
			}
			if(reachedLimit) {
				return false;
			}

			maxRowCount++;

			if(rowIndex === 10) {
				//parse out the phone number for records
				console.log(cells);
				//Get the phone number from this cell
				const phoneNumberString = cells[0];
				const phoneNumber = phoneNumberString.match(/\((\d{3})\)(\d{3})-(\d{4})/)[0];
				cleanedPhoneNumber = phoneNumber.replace(/[\(\)-]/g, '');
				cleanedPhoneNumber = "1" + cleanedPhoneNumber;
			}
			else if (rowIndex === 11) {
				// Add new item between index 1 and 2 of row array
				cells.splice(2, 0, "Date Time in " + timeZone + " Timezone	");

				//Change the value of index 4 of cells to "elapsed Time"
				cells[4] = "Elapsed Time";

				
				//add a new item between index 4 and 5 of cells array
				cells.splice(5, 0, "Incoming or Outgoing");
				
			} else if (rowIndex > 11) {
				var cellValue = cells[1]; // Remove unnecessary conversion to UTC
				
				var utcDate = dayjs(cellValue).utc(true);
				//var convertedDate = utcDate.clone();

				var formattedDate = dayjs(utcDate).tz(timeZone).format('MM/DD/YYYY HH:mm:ss');


				cells.splice(2, 0, formattedDate);


				var callDirection = "Outgoing";
				if(cells[6] == cleanedPhoneNumber)
					callDirection = "Incoming";
				cells.splice(5, 0, callDirection);


			}

			// Split the row into cells based on comma delimiter

			// Loop through each cell in the row
			cells.forEach((cell, columnIndex) => {
				
				// Write the cell value to the worksheet
				worksheet.getCell(rowIndex + 1, columnIndex + 1).value = cell;
			});

		});



		//Get row count for phone numbers only. 
		
		/*
		var phoneNumberLength = 0;
		for(var i=13; i < worksheet.rowCount; i++){
			var cellValue = worksheet.getCell('A' + i).value;
			phoneNumberLength++;
			if(cellValue.includes("Data")){
				break;
			}
		}
		*/



		//Loop through newWorksheet and get top 10 most common items in column F
		var incomingNumbersArray = [];
		var outgoingNumbersArray = [];
		var callDirection = '';

		console.log(worksheet.getCell("A13").value)

		for(var i = 13; i < maxRowCount; i++){
			callDirection = worksheet.getCell("F"+i).value;
			if(callDirection === 'Incoming'){
				var cellValue = worksheet.getCell('G' + i).value;
				incomingNumbersArray.push({phoneNumber: cellValue+""});
			}else if(callDirection === 'Outgoing'){
				var cellValue = worksheet.getCell('H' + i).value;
				outgoingNumbersArray.push({phoneNumber: cellValue+""});
			}
		}


		// Count the frequency of each value in the array
		const frequencyIncoming = incomingNumbersArray.reduce((acc, val) => {
			acc[val.phoneNumber] = (acc[val.phoneNumber] || 0) + 1;
			return acc;
		}, {});

		const frequencyOutgoing = outgoingNumbersArray.reduce((acc, val) => {
			acc[val.phoneNumber] = (acc[val.phoneNumber] || 0) + 1;
			return acc;
		}, {});

		console.log(frequencyIncoming);
		

		// Convert the frequency object to an array of [value, frequency] pairs
		const frequencyIncomingArr = Object.entries(frequencyIncoming);
		const frequencyOutgoingArr = Object.entries(frequencyOutgoing);

		// Sort the array by frequency in descending order
	
		frequencyIncomingArr.sort((a, b) => b[1] - a[1]);
		frequencyOutgoingArr.sort((a, b) => b[1] - a[1]);

		// Get the top 10 most common values
		const top10Incoming = frequencyIncomingArr.slice(0, topCallersListLength).map(pair => pair[0]);
		const top10Outgoing = frequencyOutgoingArr.slice(0, topCallersListLength).map(pair => pair[0]);
		
		console.log(top10Incoming);
		

		// Create top Incoming Caller list
		//Get all the incoming callers
		const topCallersWorksheet = workbook.addWorksheet('Top Callers');

		
		topCallersWorksheet.mergeCells('A1:B1');
		topCallersWorksheet.getCell('A1').value = "Incoming Top Callers";
		//center the value of A1
		topCallersWorksheet.getCell('A1').alignment = { horizontal: 'center' };
		topCallersWorksheet.getCell('A1').font = { bold: true };

		topCallersWorksheet.getCell('A2').value = "Number of Incoming Calls";
		topCallersWorksheet.getCell('B2').value = "Incoming Number";

		
		//topCallersWorksheet.getCell('B2').value = "Callers name (Clear Lookup)";
		for(var i = 0; i < top10Incoming.length; i++){
			topCallersWorksheet.getCell('A' + (i+3)).value = frequencyIncoming[top10Incoming[i]];
			topCallersWorksheet.getCell('B' + (i+3)).value = top10Incoming[i];
		}


		var outgoingListOffset = parseInt(topCallersListLength)+5;

		topCallersWorksheet.mergeCells('A'+outgoingListOffset+':B'+outgoingListOffset);
		topCallersWorksheet.getCell('A'+outgoingListOffset).value = "Outgoing Top Callers";
		//center the value of A15
		topCallersWorksheet.getCell('A'+outgoingListOffset).alignment = { horizontal: 'center' };
		topCallersWorksheet.getCell('A'+outgoingListOffset).font = { bold: true };

		var temp = outgoingListOffset+1;
		topCallersWorksheet.getCell('A'+temp).value = "Number of Outgoing Calls";
		topCallersWorksheet.getCell('B'+temp).value = "Outgoing Number";
		
		for(var i = 0; i < top10Outgoing.length; i++){
			topCallersWorksheet.getCell('A' + (i+outgoingListOffset+2)).value = frequencyOutgoing[top10Outgoing[i]];
			var number = top10Outgoing[i];

			topCallersWorksheet.getCell('B' + (i+outgoingListOffset+2)).value = number;
			
			
		}

		

		
		// Rest of the code...
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		const url = URL.createObjectURL(blob);
		cb(url);
	};

	reader.readAsArrayBuffer(file);
}

export default attProcessing;
import { Container, Grid } from '@mui/material';

const CustomContainer = ({ children }) => {
  return (
    <Container maxWidth="sm">
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Container>
  );
};

export default CustomContainer;
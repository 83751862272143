import React, { useEffect } from 'react';


//page imports
import Navbar from '../components/Navbar';

//Firebase
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';

import { useNavigate } from 'react-router-dom';

import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { auth } from '../firebase';
import Item from '../components/Item';

import CustomContainer from '../components/CustomContainer';

/*

*/




function Twilio() {
	const navigate = useNavigate();

	
	
	useEffect(() => {
		
		var uid = '';
		onAuthStateChanged(auth, (user) => {
			console.log("auth state changed")
			if (user) {
				uid = user.uid;
				console.log("user is logged in" + uid)
			} else {
				console.log("user is logged out")
				navigate('/');
			}
		});

	});

	//States
	const [lookupType, setLookupType] = React.useState('carrier');
	const [phoneNumber, setPhoneNumber] = React.useState('');
	const [phoneValid, setPhoneValid] = React.useState(true);
	const [buttonActive, setButtonState] = React.useState("disabled");
	const [results, setResults] = React.useState('Results...');
	//State Functions

	//Control values for carrier lookup toggle
	const control = {
		value: lookupType,
		onChange: handleChange,
		exclusive: true
	};

	//Click event for carrier lookup toggle
	function handleChange(event) {
		setLookupType(event.target.value);
	}

	//Phone number field blur event
	function phoneFieldBlur(v) {
		if(!validatePhoneNumber(v)){
			setPhoneValid(false);
		}
		else {
			setPhoneValid(true);
			setButtonState("active");
		}
	}
	function validatePhoneNumber(v) {
		const phoneNumberRegex = /^\d{10}$/;
		return phoneNumberRegex.test(v);
	}

	function processPhoneNumber(number){
		setPhoneNumber(number);
		if(phoneNumber.length == 9){
			if(!validatePhoneNumber(number)){
				
			}
			else {
				setPhoneValid(true);
				setButtonState("active");
			}
		} else if (phoneNumber.length > 9){
			setPhoneValid(false);
			setButtonState("disabled");
		}
	}

	function lookupClicked(){
		setResults("Loading...");
		setButtonState("disabled");
		document.getElementById("PhoneNumber").value = "";
		console.log(lookupType)
		console.log(phoneNumber)
		
		lookupPhoneNumber(function(results){
			console.log(results);
			var resultsString = "";
			if(lookupType == "carrier"){
				resultsString = "Phone Number: "+results.national_format+"\nCarrier: "+results.line_type_intelligence.carrier_name;
			} else if (lookupType == "name"){
				resultsString = "Phone Number: "+results.national_format+"\nSubscriber Name: "+results.caller_name.caller_name;
			}
			setResults(resultsString);
		});
	}
	


	//const [phoneNumber, setPhoneNumber] = useState('');
	//var phoneNumber = '+19142618013';
	//var type = 'caller-name'
	var type = '';
	const lookupPhoneNumber = async function (cb) {
		console.log("LOOKUP")
		const auth = getAuth();
		if(lookupType == "carrier"){
			type = 'line_type_intelligence';
		} else if (lookupType == "name"){
			type = 'caller_name';
		}
		//phoneNumber

		onAuthStateChanged(auth, async (user) => { // Add async here
			if (user) {
				// Get the user's ID token
				const idToken = await user.getIdToken(true);

				console.log("user is logged in with token: " + idToken);
				
				//check if on localhost or production
				if(window.location.hostname === "localhost")
					var baseUrl = "http://127.0.0.1:5001/letools-pg/us-central1/"
				else
					baseUrl = "https://us-central1-letools-pg.cloudfunctions.net/"

				const response = await fetch(baseUrl+"runPhoneLookup", {
					method: 'POST',
					headers: {
						'Authorization': 'Bearer ' + idToken,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						type: type,
						phoneNumber: phoneNumber
					})
				});

				const data = await response.json();
				console.log(JSON.stringify(data));
				cb(data)
			} else {
				console.log("user is logged out");
			}

		});


		
	};
	




	return(
		<>
			<CustomContainer>	
				
				<Grid xs={12} sm={12} item>
					<Navbar pageTitle={"Phone Number Lookup"} />
				</Grid>
				<Grid xs={12} sm={12} item>
					<Item>
						<Typography variant="body1"  gutterBottom>
							Easily look up phone carrier name or subscriber name for a US based telephone number.
						</Typography>

						<ToggleButtonGroup size="large" {...control} aria-label="Large sizes" fullWidth>
							<ToggleButton value="carrier" key="left">
								Carrier Lookup
							</ToggleButton>
							<ToggleButton value="name" key="right">
								Name Lookup 
							</ToggleButton>
						</ToggleButtonGroup>
						<br/><br/>
						<TextField
							id="PhoneNumber"
							label="Phone Number (no dashes or spaces)"
							variant="outlined"
							fullWidth
							onChange={(event) => processPhoneNumber(event.target.value)}
							onBlur={(event) => phoneFieldBlur(event.target.value)}
							error={!phoneValid}
						/>
						<br/><br/>
						<TextField
							id="results"
							multiline
							disabled
							fullWidth
							maxRows={4}
							value={results}
							/>
						<br/><br/>
						<Button variant="contained" fullWidth disabled={buttonActive === "disabled"} onClick={lookupClicked}>Lookup</Button>
						<br/><br/>
						<Typography variant="caption" display="block" >
							Disclaimer: This uses a 3rd party service.
						</Typography>
					</Item>
				</Grid>
			</CustomContainer>

		</>
		

	)

}

export default Twilio;
//react router dom import
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

//TO DEPLOY:
//npm run build
// node_modules/.bin/firebase deploy

//TO RUN LOCALLY:
//npm start


//page imports
import Home from './pages/Home';
import Cdrs from './pages/Cdrs';
import Login from './pages/Login';
import Twilio from './pages/Twilio';
import Logout from './pages/Logout';
import Ipcheck from './pages/Ipcheck';
import TestPage from "./pages/TestPage";
import DVRTime from "./pages/DVRTime";
import TowerDump from "./pages/TowerDump";
import UTCConverter from "./pages/UTCConverter";
import CommonAnalysis from "./pages/CommonAnalysis";

function App() {
 
	return (
		<CssBaseline>
			<Router>                        
			<Routes>                                                                       
				<Route path="/" element={<Home/>}/>
				<Route path="/cdrs" element={<Cdrs/>}/>
				<Route path="/phonelookup" element={<Twilio/>}/>
				<Route path="/login" element={<Login/>}/>
				<Route path="/logout" element={<Logout/>}/>
				<Route path="/ipcheck" element={<Ipcheck/>}/>
				<Route path="/testPage" element={<TestPage/>}/>
				<Route path="/dvrtime" element={<DVRTime/>}/>
				<Route path="/towerdump" element={<TowerDump/>}/>
				<Route path="/utcconverter" element={<UTCConverter/>}/>
				<Route path="/commonanalysis" element={<CommonAnalysis/>}/>
			</Routes>                    
		</Router>

		</CssBaseline>
		
	);
}
 
export default App;
import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import Item from '../components/Item';
import CustomContainer from '../components/CustomContainer';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

function Home() {
	const navigate = useNavigate();

	const [isLoggedIn, setIsLoggedIn] = React.useState(false);
	useEffect(() => {
		
		var uid = '';
		onAuthStateChanged(auth, (user) => {
			console.log("auth state changed")
			if (user) {
				uid = user.uid;
				console.log("user is logged in" + uid)
				setIsLoggedIn(true);
			} else {
				console.log("user is logged out")
				setIsLoggedIn(false);
			}
		});

	});

	return (
		<>
			<CustomContainer>
				<Grid  xs={12} md={6} item >
					<Item style={{ minHeight: '270px' }}>
						<Typography variant="h2" gutterBottom>
								LE Tools
							</Typography>
							<Typography variant="body1" gutterBottom>
								A set of custom tools to help you do your job better. Have a suggestion for a tool or found a problem? Let me know. <br/> 
							</Typography>
							<br/>
							{isLoggedIn && (
								<Link to="/logout" color="primary" >
									<LogoutIcon sx={{ cursor: 'pointer' }}/>
								</Link>
							)}
							{!isLoggedIn && (
								<Link to="/login" color="primary" >
									<LoginIcon sx={{ cursor: 'pointer' }}/>
								</Link>
							)}
							
					</Item>
				</Grid>
				<Grid xs={12} md={6} item>
					<Item style={{ minHeight: '270px' }}>
						<div className="imgContainer"></div>
						<br/>
						<Link to="/cdrs">
							<Button variant="outlined" fullWidth>CDR Processing</Button>
						</Link>
					</Item>
				</Grid>
				<Grid xs={12} md={6} item>
					<Item style={{ minHeight: '270px' }}>
						<div className="imgContainer-dvr"></div>
						<br/>
						<Link to="/dvrtime">
							<Button variant="outlined" fullWidth>DVR Time Converter</Button>
						</Link>
					</Item>
				</Grid>
				<Grid xs={12} md={6} item>
					<Item style={{ minHeight: '270px' }}>
						<div className="imgContainer-utc"></div>
						<br/>
						<Link to="/utcconverter">
							<Button variant="outlined" fullWidth>UTC Converter</Button>
						</Link>
					</Item>
				</Grid>
				<Grid xs={12} md={6} item>
					<Item style={{ minHeight: '270px' }}>
						<div className="imgContainer-tower"></div>
						<br/>
						<Link to="/towerdump">
							<Button variant="outlined" fullWidth>T-Mobile Tower Dump</Button>
						</Link>
					</Item>
				</Grid>
				{isLoggedIn && (
					<Grid xs={12} md={6} item>
						<Item style={{ minHeight: '270px' }}>
							<div className="imgContainer-phonelookup"></div>
							<br/>
							<Link to="/phonelookup">
								<Button variant="outlined" fullWidth>Phone Lookup</Button>
							</Link>
						</Item>
					</Grid>
				)}
				{isLoggedIn && (
					<Grid xs={12} md={6} item>
						<Item style={{ minHeight: '270px' }}>
							<div className="imgContainer-iplookup"></div>
							<br/>
							<Link to="/ipcheck">
								<Button variant="outlined" fullWidth>IP Checker</Button>
							</Link>
						</Item>
					</Grid>
				)}
				<Grid xs={12} md={6} item>
					<Item style={{ minHeight: '270px' }}>
						<div className="imgContainer-common"></div>
						<br/>
						<Link to="/commonanalysis">
							<Button variant="outlined" fullWidth>Common Analysis</Button>
						</Link>
					</Item>
				</Grid>
				<Grid xs={12} md={6} item>
					<Item style={{ minHeight: '270px' }}>
						<div className="imgContainer-maximind"></div>
						<br/>
						<Link to="https://www.maxmind.com/en/geoip-demo" target="_blank">
							<Button variant="outlined" fullWidth>Maximind IP lookup demo</Button>
						</Link>
					</Item>
				</Grid>
				<Grid xs={12} md={6} item>
					<Item style={{ minHeight: '270px' }}>
						<div className="imgContainer-search"></div>
						<br/>
						<Link to="https://www.search.org/resources/isp-list/" target="_blank">
							<Button variant="outlined" fullWidth>Subpoena Service Tips</Button>
						</Link>
					</Item>
				</Grid>
			</CustomContainer>
		</>
	);
}

export default Home;
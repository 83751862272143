import React, { useEffect } from 'react';


//page imports
import Navbar from '../components/Navbar';

//Firebase
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';

import { useNavigate } from 'react-router-dom';

import Item from '../components/Item';

import CustomContainer from '../components/CustomContainer';


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment-timezone';

import Select from '@mui/material/Select';


import { set } from 'date-fns';
dayjs.extend(utc);
dayjs.extend(timezone);

/*

*/




function UTCConverter() {
	const [usersTimeLabel, setUsersTimeLabel] = React.useState("Select UTC Time to Convert");
	const [usersTime, setUsersTime] = React.useState(new Date());

	const [results, setResults] = React.useState("");
	const [offset, setOffset] = React.useState("");
	

	const[timeZone, setTimeZone] = React.useState('America/New_York');
	const timeZones = moment.tz.names();

	const [datePickerTZ, setDatePickerTZ] = React.useState('UTC');

	const doUpdateTimezone = function(e){
		setTimeZone(e.target.value);
	}
	
	//Date Time Picker
	const usersTimeChanged = function(e){
		setUsersTime(e);
		
		
	}


	const doTimeConversion = function(){

		var time = usersTime;

		console.log(timeZone);
		// Set time as UTC time

		if (alignment === 'left') {
			var localTime = dayjs(time).tz(timeZone).format('MM/DD/YYYY HH:mm (h:mm A)');
			setResults("Local Time: " + localTime);
			setOffset("Offset: " + dayjs(time).tz(timeZone).utcOffset() / 60 + " Hours");
		} else if (alignment === 'right') {
			var utcTime = dayjs(time).utc().format('MM/DD/YYYY HH:mm (h:mm A)');
			setResults("UTC Time: " + utcTime);
			setOffset("Offset: " + dayjs(time).tz(timeZone).utcOffset() / 60 + " Hours");
		}
	}


	//Toggle controls
	const [alignment, setAlignment] = React.useState('left');
	const handleChange = (event, newAlignment) => {
		setAlignment(newAlignment);
		if(event.target.value === 'left') {
			setUsersTimeLabel("Select UTC Time to Convert");
			setDatePickerTZ('UTC');
		}
		else if(event.target.value === 'right'){
			setUsersTimeLabel("Select Local Time to Convert");
			setDatePickerTZ(timeZone);
		}
	};
	const control = {
		value: alignment,
		onChange: handleChange,
		exclusive: true
	};

	const converTime = function(){
		doTimeConversion();
	};

	return(
		<>
			<CustomContainer>	
				
				<Grid xs={12} sm={12} item>
					<Navbar pageTitle={"Convert UTC Time"} />
				</Grid>
				<Grid xs={12} sm={12} item>
					<Item>
					<Typography variant="body1"  gutterBottom>
						Quickly convert between UTC and Local Time
					</Typography>
					</Item>
				</Grid>
				<Grid xs={12} sm={12} item>
					<Item>
						

						<Typography variant="body1"  gutterBottom>
							Choose your timezone 
						</Typography>
						<Select 
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={timeZone}
							label="some label"
							fullWidth
							onChange={doUpdateTimezone}>
							{timeZones
							.sort((a, b) => {
								if (a.startsWith('US/')) return -1;
								if (b.startsWith('US/')) return 1;
								if (a === 'America/New_York') return -1;
								if (b === 'America/New_York') return 1;
								if (a.includes("America/") && !b.includes("America/")) {
									return -1;
								} else if (!a.includes("America/") && b.includes("America/")) {
									return 1;
								} else {
									return a.localeCompare(b);
								}
							})
							.map((timeZone, index) => (
								<MenuItem key={index} value={timeZone}>
									{timeZone}
								</MenuItem>
							))}
						</Select>

						<br/><br/>

						<ToggleButtonGroup size="large" {...control} aria-label="Large sizes" fullWidth>
							<ToggleButton value="left" key="left">
								UTC to Local Time
							</ToggleButton>
							<ToggleButton value="right" key="right">
								Local Time to UTC
							</ToggleButton>
						</ToggleButtonGroup>

						<br/><br/>
						

			

						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DemoContainer components={['DateTimePicker']}>
								<DateTimePicker timezone ={datePickerTZ} label={usersTimeLabel} onChange={usersTimeChanged}/>
							</DemoContainer>
						</LocalizationProvider>

						<Typography variant="body1"  gutterBottom>
							<br/>
							{results}
							<br/>
							{offset}
						</Typography>

						<Button fullWidth variant="contained" color="primary" onClick={converTime} >
							Convert Time
						</Button>
						
					</Item>
					<br/><br/>
				</Grid>
			</CustomContainer>

		</>
		

	)

}

export default UTCConverter;
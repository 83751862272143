import React, { useState } from 'react';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment-timezone';
import { useRef } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


//MUI compontents
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';


//page imports
import Navbar from '../components/Navbar';

//Firebase
import {db} from '../firebase';
import { doc, setDoc, getDoc} from 'firebase/firestore';	

import Item from '../components/Item';
import CustomContainer from '../components/CustomContainer';

import attProcessing from '../components/attProcessing';
import vzwProcessing from '../components/vzwProcessing';


function TowerDump() {
	const fileInput = useRef(null);
	const fileZipInput = useRef(null);
	const [file, setFile] = useState();
	
	const [zipFile, setZipFile] = useState();

	const [fileName, setFileName] = useState('Select a file');
	const [zipFileName, setZipFileName] = useState('Select a file');

	const [towerKey, setTowerKey] = useState({});
	
	function handleFileChange(e) {
		setFile(e.target.files[0]);
		setFileName(e.target.files[0].name)
	}
	const handleButtonClick = () => {
		fileInput.current.click();
		console.log('button click');
	};

	function handleZipFileChange(e) {
		setZipFile(e.target.files[0]);
		setZipFileName(e.target.files[0].name)
	}
	const handleZipButtonClick = () => {
		fileZipInput.current.click();
		console.log('button click');
	};


	async function handleFileRead() {
		
		const reader = new FileReader();
		
		reader.onload = async function (e) {
			const bstr = e.target.result;
			const workbook = new ExcelJS.Workbook();
			await workbook.xlsx.load(bstr);

			// Get the first worksheet
			const worksheet = workbook.worksheets[0];

			if (!worksheet)
				return console.log('errorssss') //processError('Error: Could not find the first worksheet. Make sure you upload the correct file.');

			//Loop through each row starting at row 9
			var keyObject = {};
			worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
				if (rowNumber >= 9) {
					//console.log(row.values);
					var key = row.getCell(1).value+" "+row.getCell(2).value+" "+row.getCell(3).value
					//replace all spaces with underscores
					key = key.replace(/ /g, "_");
					//replace all slashes with underscores
					key = key.replace(/\//g, "_");
					//replace all colons with underscores
					key = key.replace(/:/g, "_");
					console.log(key)

					//Check if key exists in keyObject
					if (keyObject[key]) {
						//If it exists, add the cell value to the key
						keyObject[key].push(row.getCell(7).value);
					} else {
						//If it doesn't exist, create a new key with the cell value
						keyObject[key] = [row.getCell(7).value];
					}
					
				}
			})

			setTowerKey(keyObject);
			console.log(keyObject);
			
			handleZipFileRead(keyObject);
			
			
		}
		reader.readAsArrayBuffer(file);
	}

	async function handleZipFileRead(towerKey) {

	
		//Get file name of zip file
		const reader = new FileReader();

		reader.onload = async function (e) {
			

			//loop through each object in towerKey
			console.log("TOWER KEY", towerKey);
			//console.log(fileNames.length)

			var towersKey = [];
			var towersKeyCount = 0;

			for (const [key, value] of Object.entries(towerKey)) {
				towersKey.push(key);
			}

			async function runFileProcessing(towersKeyCount) {
				
				//unzip zipFile
				const zip = new JSZip();
				const bstr = e.target.result;
				const zipFileResult = await zip.loadAsync(bstr);

				//list all the file names in the zip file
				var fileNames = Object.keys(zipFileResult.files);
				console.log("FILENAMES", fileNames);

				//create a new zip file to add files to
				var newZip = new JSZip();

				var value = towerKey[towersKey[towersKeyCount]]
				console.log(value)
				for(var i = 0; i < value.length; i++) {
					for(var j = 0; j < fileNames.length; j++){
						//check if the file name contains the key
			
						var tempFileName = fileNames[j].replace(/_/g, "");
						var tempValue = value[i].replace(/_/g, "");;
						//console.log(tempFileName, tempValue, tempFileName.includes(tempValue))
						if (tempFileName.includes(tempValue)) {
							console.log("MATCH", tempFileName, tempValue);
							//if it does, add it to the new zip file
							newZip.file(fileNames[j], zipFileResult.files[fileNames[j]].async("blob"));
						}
					}
				}


				newZip.generateAsync({type:"blob"})
				.then(function(content) {
					saveAs(content, towersKey[towersKeyCount]+".zip");
					towersKeyCount++;
					if(towersKeyCount < towersKey.length) {
						setTimeout(function(){
							runFileProcessing(towersKeyCount)
						}, 1000)
						
					}
				});

			}
			runFileProcessing(towersKeyCount)
			
			/*
			//get each object in towerKey
			for (const [key, value] of Object.entries(towerKey)) {
				//loop through each file in the zip file
				console.log("KEY VAL", key, value)
				for(var i = 0; i < value.length; i++) {
					for(var j = 0; j < fileNames.length; j++){
						//check if the file name contains the key
			
						var tempFileName = fileNames[j].replace(/_/g, "");
						var tempValue = value[i].replace(/_/g, "");;
						//console.log(tempFileName, tempValue, tempFileName.includes(tempValue))
						if (tempFileName.includes(tempValue)) {
							//console.log("MATCH", tempFileName, tempValue);
							//if it does, add it to the new zip file
							newZip.file(fileNames[j], zipFileResult.files[fileNames[j]].async("blob"));
						}
					}
				}


				newZip.generateAsync({type:"blob"})
				.then(function(content) {
					saveAs(content, key+".zip");
				});
			}
			*/
			

		}


		
		reader.readAsArrayBuffer(zipFile);

	}
		

	return(
		<>
			<CustomContainer>	
				
				<Grid xs={12} sm={12} item>
					<Navbar pageTitle={"Tower Dump Processing"} />
				</Grid>
				<Grid xs={12} sm={12} item>
					<Item>
						<Typography variant="body1"  >
							This T-Mobile tower dump tool takes indiviudal excel files for various tower dump records and combines them into individual zip files based on where the tower is located. <br/><br/>
						</Typography>
						<Typography variant="h6"  >
							Upload Tower Dump Key File 
						</Typography>
						<Typography variant="body2"  >
							The tower Dump Key File typically starts with a number and ends in: <br/> "_Tower Dump Key.xlsx"
						</Typography>
						<Typography variant="body2"  >
							<br/>
							{fileName}
						</Typography>
						<input
							type="file"
							ref={fileInput}
							onChange={handleFileChange}
							style={{ display: 'none' }} // Hide the default file input
						/>
						<Button fullWidth variant="outlined" color="primary" onClick={handleButtonClick}>
							Choose File
						</Button>
						
					</Item>
					<Item>
						<Typography variant="h6"  >
							Upload all tower dump files in single zip file
						</Typography>
						<Typography variant="body2"  >
							The Tower Dump Key File should not be included in the zip file that contains all tower dump data
						</Typography>
						<Typography variant="body2"  >
							<br/>
							{zipFileName}
						</Typography>
						<input
							type="file"
							ref={fileZipInput}
							onChange={handleZipFileChange}
							style={{ display: 'none' }} // Hide the default file input
						/>
						<Button fullWidth variant="outlined" color="primary" onClick={handleZipButtonClick}>
							Choose File
						</Button>
						
					</Item>
					<Item>
						<Button fullWidth variant="contained" color="primary" onClick={handleFileRead} >
							Process
						</Button>

						<Typography variant="body1"  >
							<br/>
							Once processed, your tower dump files will be downloaded as individual zip files and grouped by location. 
						</Typography>
						<Typography variant="caption"  >
							<br/>
							All processing is done locally, nothing is uploaded to our server. 
						</Typography>
					</Item>
				</Grid>
			</CustomContainer>

			







			
		</>
		

	)
	/*
	return (
		<div>
			<h1>Excel Manipulator</h1>
			<select onChange={doUpdateTimezone}>
				{timeZones
					.sort((a, b) => {
						if (a === 'America/New_York') return -1;
						if (b === 'America/New_York') return 1;
						if (a.includes("America/") && !b.includes("America/")) {
							return -1;
						} else if (!a.includes("America/") && b.includes("America/")) {
							return 1;
						} else {
							return a.localeCompare(b);
						}
					})
					.map((timeZone, index) => (
						<option key={index} value={timeZone}>
							{timeZone}
						</option>
					))}
			</select>
			<input type="file" onChange={handleFileChange} />
			<button onClick={handleFileRead}>Read File</button>
		</div>
	);
	*/
}

export default TowerDump;
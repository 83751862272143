import React, { useEffect } from 'react';


//page imports
import Navbar from '../components/Navbar';

//Firebase
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';

import { useNavigate } from 'react-router-dom';

import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { auth } from '../firebase';
import Item from '../components/Item';

import CustomContainer from '../components/CustomContainer';


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment-timezone';
import { set } from 'date-fns';

/*

*/




function DVRTime() {
	const navigate = useNavigate();

	const [buttonActive, setButtonActive] = React.useState(false);
	
	const [currentTime, setCurrentTime] = React.useState(dayjs().format('MM/DD/YYYY HH:mm'));
	
	setInterval(function() {
		setCurrentTime(dayjs().format('MM/DD/YYYY HH:mm:ss'));
	}, 1000);

	const [incidentTime, setIncidentTime] = React.useState();
	
	const incidentTimeChanged = function(e){

		setIncidentTime(e);
		//doTimeConversion();
	}

	const [dvrTime, setDvrTime] = React.useState();

	const dvrTimeChanged = function(e){

		setDvrTime(e);
		//doTimeConversion();
		console.log(e)
		setButtonActive(true);
	}


	const [timeDiff, setTimeDiff] = React.useState();
	const [timeDiffString, setTimeDiffString] = React.useState();
	const [dvrTimeConverted, setDvrTimeConverted] = React.useState();

	const convertTime = function(){
		


		if(incidentTime)
			console.log("Incident time", incidentTime.format('MM/DD/YYYY HH:mm:ss'))
		if(dvrTime)
			console.log("DVR TIme", dvrTime.format('MM/DD/YYYY HH:mm:ss'))

		//Figure out difference between dvrTime and currentTime
		//Add that difference to incidentTime
		//Display new time
		var diff = dvrTime.diff(dayjs(), 'minute');

		console.log("diff", diff)
		console.log(incidentTime.add(diff, 'minute').format('MM/DD/YYYY HH:mm:ss'))
		
		var incidentTimeOnDvr = incidentTime.add(diff, 'minute').format('MM/DD/YYYY HH:mm A');

		if(mode == "unknownIncidentTime"){
			incidentTimeOnDvr = incidentTime.subtract(diff, 'minute').format('MM/DD/YYYY HH:mm:ss');
		}

		var displayedDifference = "";
		var tempDiff = 0;
		//convert diff to hours and minutes
		if (diff > 60) {
			var hours = Math.floor(diff / 60);
			var minutes = diff % 60;
			if (hours >= 24) {
				var days = Math.floor(hours / 24);
				hours = hours % 24;
				displayedDifference = days + " days, " + hours + " hours, and " + minutes + " minutes";
			} else {
				displayedDifference = hours + " hours and " + minutes + " minutes";
			}
		} else if (diff < -60) {
			tempDiff = diff * -1;
			var hours = Math.floor(tempDiff / 60);
			var minutes = tempDiff % 60;
			if (hours >= 24) {
				var days = Math.floor(hours / 24);
				hours = hours % 24;
				displayedDifference = days + " days, " + hours + " hours, and " + minutes + " minutes";
			} else {
				displayedDifference = hours + " hours and " + minutes + " minutes";
			}
		} else {
			displayedDifference = diff + " minutes";
		}

		if(mode === 'knownIncidentTime'){
			if(diff > 0){
				setTimeDiff(diff);
				setTimeDiffString("DVR is ahead of current time by " + displayedDifference + ".");
				setDvrTimeConverted("Incident time on DVR is " + incidentTimeOnDvr );
			}
			else if(diff < 0){
				setTimeDiff(diff);
				setTimeDiffString("DVR is behind current time by " + displayedDifference + ".");
				setDvrTimeConverted("Incident time on DVR is " + incidentTimeOnDvr );
			}
			else{
				setTimeDiff(diff);
				setTimeDiffString("DVR is synced with current time. Incident time on DVR is " + incidentTimeOnDvr + "");
				setDvrTimeConverted("Incident time on DVR is " + incidentTimeOnDvr );
			}
		} else if(mode === 'unknownIncidentTime'){
			if(diff > 0){
				setTimeDiff(diff);
				setTimeDiffString("DVR is ahead of current time by " + displayedDifference + ".");
				setDvrTimeConverted("Real time of incident is " + incidentTimeOnDvr );
			}
			else if(diff < 0){
				setTimeDiff(diff);
				setTimeDiffString("DVR is behind current time by " + displayedDifference + ".");
				setDvrTimeConverted("Real time of incident is " + incidentTimeOnDvr );
			}
			else{
				setTimeDiff(diff);
				setTimeDiffString("DVR is synced with current time.");
				setDvrTimeConverted("Real time of incident is " + incidentTimeOnDvr );
			}
		}
		

	}

	//Toggle controls
	const [alignment, setAlignment] = React.useState('left');
	const [mode, setMode] = React.useState('knownIncidentTime');
	const [incedentTimePickerLabel, setIncidentTimePickerLabel] = React.useState('Known Incident Time');
	const handleChange = (event, newAlignment) => {
		setAlignment(newAlignment);
		if(event.target.value === 'left') {
			setMode('knownIncidentTime');
			setIncidentTimePickerLabel('Known Incident Time');
		}
		else if(event.target.value === 'right'){
			setMode('unknownIncidentTime');
			setIncidentTimePickerLabel('DVR Incident Time');
		}
	};
	const control = {
		value: alignment,
		onChange: handleChange,
		exclusive: true
	};

	return(
		<>
			<CustomContainer>	
				
				<Grid xs={12} sm={12} item>
					<Navbar pageTitle={"DVR Time Converter"} />
				</Grid>
				<Grid xs={12} sm={12} item>
					<Item>
						<Typography variant="body1"  gutterBottom>
							Quick tool to convert incident time to DVR time when reviewing DVR video where the time is not synced with the current time. <br/><br/> Enter incident time
						</Typography>


						<ToggleButtonGroup size="large" {...control} aria-label="Large sizes" fullWidth>
							<ToggleButton value="left" key="left">
								Known Incident Time
							</ToggleButton>
							<ToggleButton value="right" key="right">
								Unknown Incident Time
							</ToggleButton>
						</ToggleButtonGroup>
						<br/><br/>

						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DemoContainer components={['DateTimePicker']}>
								<DateTimePicker label={incedentTimePickerLabel} onChange={incidentTimeChanged}/>
							</DemoContainer>
						</LocalizationProvider>
						<br/>
						<Typography variant="body1"  gutterBottom>
							Current Time: {currentTime}
						</Typography>

						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DemoContainer components={['DateTimePicker']}>
								<DateTimePicker label="Current DVR Time" onChange={dvrTimeChanged}/>
							</DemoContainer>
						</LocalizationProvider>
						<br/>
						<Typography variant="body1"  gutterBottom>
							{timeDiffString}
							<br/>
							{dvrTimeConverted}
						</Typography>
						<br/>
						<Button fullWidth variant="contained" color="primary" onClick={convertTime} disabled={!buttonActive}>
							Convert Time
						</Button>
					</Item>
				</Grid>
			</CustomContainer>

		</>
		

	)

}

export default DVRTime;
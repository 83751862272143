
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

//Import firebase auth
import { signInWithEmailAndPassword, onAuthStateChanged, getAuth } from 'firebase/auth';
import { auth } from '../firebase';











function Logout() {
	const navigate = useNavigate();
	const auth = getAuth();
	useEffect(() => {
		doLogout();
	}, []);

	function doLogout() {

		console.log("logging out")
		
		auth.signOut()
			.then(() => {
				// Logout successful
				// Add any additional logic here
		
				navigate('/');
			})
			.catch((error) => {
				// Handle logout error
				// Add any error handling logic here
	
				navigate('/');
			});
			
	}

	return (
		<>
			
		</>
	);
}

export default Logout;